<template>
  <div class="edit-activity">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="活动名称"
        :rules="[
          { required: true, message: '请填写活动名称', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input
          placeholder="请输入活动名称"
          v-model="form.title"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="用户类别"
        prop="authSpaceTypes"
        :rules="[
          { required: true, validator: spaceTypeValid, trigger: 'blur' },
        ]"
      >
        <div class="check-panel">
          <v-checkbox
            v-model="item.checked"
            v-for="(item, index) in userTypeOps"
            :key="index"
            :index="index"
            type="border"
            :label="item.label"
            @change="checkChange"
          />
        </div>
      </el-form-item>

      <el-form-item
        v-show="form.authSpaceTypes !== undefined"
        label="发布区域"
        prop="authSpaceIds"
        :rules="[
          { required: false, message: '请选择调查区域', trigger: 'blur' },
        ]"
      >
        <v-cascader
          placeholder="默认表示全部"
          :width="width"
          v-model="authSpaceIds"
          :options="sectionOps"
          :props="props"
          @change="sectionChange"
        />
      </el-form-item>

      <el-form-item
        label="活动时间"
        :rules="[
          { required: true, message: '请选择活动时间', trigger: 'change' },
        ]"
        prop="selectDate"
      >
        <v-date-picker
          v-model="form.selectDate"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="活动开始时间"
          endPlaceholder="活动结束时间"
        />
      </el-form-item>
      <el-form-item
        label="活动状态"
        :rules="[
          { required: true, message: '请选择活动状态', trigger: 'change' },
        ]"
        prop="shelfSts"
      >
        <v-select :options="activityStatus" v-model="form.shelfSts" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { activityAdd, activityDetails, getBuildListURL } from "./api";
import { activityStatus, setUserTypeOps } from "./map";

export default {
  name: "editActivity",
  props: {},
  data() {
    return {
      userTypeOps: setUserTypeOps(),
      activityStatus,
      submitConfig: {
        queryUrl: activityDetails,
        submitUrl: activityAdd,
      },
      props: {
        multiple: true,
        label: "name",
        value: "id",
        children: "children",
      },
      width: 350,
      authSpaceIds: [],
      form: {
        authSpaceTypes: undefined, // 用户类别id
        allSpaces: 1, // 是否所有区域 1 是 0 否
        authSpaceIds: "", // 区域id集合
        title: null,
        selectDate: null,
        endDate: null,
        startDate: null,
        id: null,
        shelfSts: "",
      },
      sectionOps: [], // 用于显示的数据
      residentsOps: [], // 用户
      merchantsOps: [], // 商圈
      allOps: [], // 用户+商圈
      spacePathIds: [], // 添加treeIds的数据
    };
  },
  computed: {
    authSpaceTypes() {
      return this.form.authSpaceTypes;
    },
  },
  watch: {
    // 选中的用户类别集合
    userTypeOps: {
      handler(val) {
        const checkOps = val.filter((v) => v.checked);
        this.form.authSpaceTypes =
          checkOps.length > 0
            ? checkOps.map((v) => v.value).join(",")
            : undefined;
      },
      deep: true,
    },
    authSpaceTypes() {
      let types =
        this.form.authSpaceTypes !== undefined
          ? this.form.authSpaceTypes.split(",")
          : [];
      let result = [];
      if (types.includes("5") && types.includes("4")) {
        result = [...this.allOps];
      } else if (types.includes("5")) {
        result.push(...this.merchantsOps);
      } else if (types.includes("4")) {
        result.push(...this.residentsOps);
      }
      this.sectionOps = result;
    },
    // 选中的区域集合
    authSpaceIds: {
      handler(val) {
        this.form.authSpaceIds = val.map((a) => a[a.length - 1]).join(",");
        console.log(this.form.authSpaceIds);
      },
      deep: true,
    },
  },
  async mounted() {
    const { id } = this.$route.query;
    await Promise.all([
      this.getBuildList(4),
      this.getBuildList(5),
      this.getBuildList("4,5"),
    ]);
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ topicId: id });
      });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    sectionChange(val) {
      console.log(val, this.form);
    },
    checkChange() {
      // 每次改变时, 清空默认选择项
      this.authSpaceIds = [];
    },
    async getBuildList(type) {
      let res = await this.$axios.get(
        `${getBuildListURL}?specifyCollectionTypes=${type}`
      );
      if (res.code === 200) {
        // type = 1 居民 2 商户
        // const maxLevel = type === 4 ? 3 : 2;
        let opt = this.dealDataAddParentIds(res.data);
        // 给res.data添加了treeIds
        // this.spacePathIds.push(...(res.data || []));
        // const ops = [
        //   ...(this.dealDataToTree(res.data, maxLevel)[0]
        //     ? this.dealDataToTree(res.data, maxLevel)[0].children
        //     : []),
        // ];
        console.log(res.data, opt, "opt---");
        // 4 用户 5 商圈
        if (type === 4) {
          this.residentsOps = opt;
        } else if (type === 5) {
          this.merchantsOps = opt;
        } else {
          this.allOps = opt;
        }
      }
    },
    // 数据添加 parentIds
    dealDataAddParentIds(data, ids = []) {
      data.forEach((v) => {
        if (v.children) {
          this.dealDataAddParentIds(v.children, [...ids, v.id]);
        } else {
          this.spacePathIds.push(v);
        }
        v.treeIds = [...ids, v.id];
      });
      return data;
    },
    spaceTypeValid(rule, value, callback) {
      if (value === undefined) {
        callback(new Error("请选择用户类别"));
      } else {
        callback();
      }
    },
    submitBefore() {
      console.log(this.form);
      this.form.startDate = new Date(this.form.selectDate[0]).format(
        "yyyy-MM-dd HH:mm"
      );
      this.form.endDate = new Date(this.form.selectDate[1]).format(
        "yyyy-MM-dd HH:mm"
      );
      return true;
    },
    submitSuccess() {
      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        if (key === "authSpaceTypes" && !data[key]) {
          this.form[key] = "";
          return;
        }
        this.form[key] = data[key];
      });
      const ids = this.form.authSpaceIds
        ? this.form.authSpaceIds.split(",")
        : [];
      this.spacePathIds.forEach((item) => {
        if (ids.includes(item.id)) {
          this.authSpaceIds.push([...item.treeIds]);
        }
      });
      let types = this.form.authSpaceTypes.split(",");
      this.userTypeOps.forEach((v) => {
        if (types.includes(v.value)) {
          v.checked = true;
        }
      });
      if (this.form.startDate && this.form.endDate) {
        this.form.selectDate = [];
        this.form.selectDate[0] = new Date(this.form.startDate);
        this.form.selectDate[1] = new Date(this.form.endDate);
      }
      if (this.form.communityIds) {
        this.form.communitys = this.form.communityIds.split(",");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.edit-activity {
  box-sizing: border-box;
  height: 100%;
  .check-panel {
    display: flex;
  }
}
</style>
