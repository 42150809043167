var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-activity"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"活动名称","rules":[
          { required: true, message: '请填写活动名称', trigger: 'blur' },
        ],"prop":"title"}},[_c('v-input',{attrs:{"placeholder":"请输入活动名称","width":250,"maxlength":50},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"用户类别","prop":"authSpaceTypes","rules":[
          { required: true, validator: _vm.spaceTypeValid, trigger: 'blur' },
        ]}},[_c('div',{staticClass:"check-panel"},_vm._l((_vm.userTypeOps),function(item,index){return _c('v-checkbox',{key:index,attrs:{"index":index,"type":"border","label":item.label},on:{"change":_vm.checkChange},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})}),1)]),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.authSpaceTypes !== undefined),expression:"form.authSpaceTypes !== undefined"}],attrs:{"label":"发布区域","prop":"authSpaceIds","rules":[
          { required: false, message: '请选择调查区域', trigger: 'blur' },
        ]}},[_c('v-cascader',{attrs:{"placeholder":"默认表示全部","width":_vm.width,"options":_vm.sectionOps,"props":_vm.props},on:{"change":_vm.sectionChange},model:{value:(_vm.authSpaceIds),callback:function ($$v) {_vm.authSpaceIds=$$v},expression:"authSpaceIds"}})],1),_c('el-form-item',{attrs:{"label":"活动时间","rules":[
          { required: true, message: '请选择活动时间', trigger: 'change' },
        ],"prop":"selectDate"}},[_c('v-date-picker',{attrs:{"type":"datetimerange","formatValue":"yyyy-MM-dd HH:mm:ss","startPlaceholder":"活动开始时间","endPlaceholder":"活动结束时间"},model:{value:(_vm.form.selectDate),callback:function ($$v) {_vm.$set(_vm.form, "selectDate", $$v)},expression:"form.selectDate"}})],1),_c('el-form-item',{attrs:{"label":"活动状态","rules":[
          { required: true, message: '请选择活动状态', trigger: 'change' },
        ],"prop":"shelfSts"}},[_c('v-select',{attrs:{"options":_vm.activityStatus},model:{value:(_vm.form.shelfSts),callback:function ($$v) {_vm.$set(_vm.form, "shelfSts", $$v)},expression:"form.shelfSts"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }